import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import GatsbyLink from 'gatsby-link';
import SEO from '../utils/seo';
import tw, { GlobalStyles } from 'twin.macro'

const Container = tw.div`
  container
  mx-auto
`;

const Layout = ({ children, ...rest }) => {

  const changeVhVariable = () => {
    const vh = typeof window !== 'undefined' && window.innerHeight * 0.01;
    typeof document !== 'undefined' &&
      document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    changeVhVariable();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <GlobalStyles />
      <SEO />
      <Container>
        {children}
      </Container>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;