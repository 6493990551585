import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ meta, lang, title }) => {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            social {
              twitter
            }
            imageShare
          }
        }
      }
    `
    );

    const metaDescription = site.siteMetadata.description;
    const metaImage = `${site.siteMetadata.siteUrl}/${
        site.siteMetadata.imageShare
        }`;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title || site.siteMetadata.title}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: 'og:url',
                    content: site.siteMetadata.siteUrl,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: `@${site.siteMetadata.social.twitter}`,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ]
                .concat([
                    {
                        property: 'og:image',
                        content: metaImage,
                    },
                    {
                        name: 'twitter:image',
                        content: metaImage,
                    },
                ])
                .concat(meta)}
        />
    );
};

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
};

export default SEO;